<template>
    <div>
        <p v-if="isTransactionError">We're having trouble processing your payment. Please try again later.</p>

        <av-payment-method-credit-card
            v-show="isWidgetShowing"
            ref="jest-authvia-component"
            id="avcc"
            :data-token="tokenValue"
            :data-azp="merchantClientID"
            data-submit-label="Place Order">
        </av-payment-method-credit-card>

        <h1 v-show="isCreatingTransaction">Creating Transaciton...</h1>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

export default {
	name: 'AuthviaCreditCard',

	data() {
		return {
			isTransactionError: false
		};
	},

	computed: {
		...mapState('authvia', [
			'tokenValue',
			'merchantClientID',
			'isCreatingTransaction',
			'isCreateTransactionSuccessful',
			'paymentMethodSuccess'
		]),
		...mapGetters('authvia', ['paymentMethodID']),
		...mapState('dealer', ['selected']),
		...mapState('cart/order', ['leadID']),
		...mapGetters('cart/order', ['grandTotalAmount']),
		isWidgetShowing() {
			return !this.isCreatingTransaction && !this.isCreateTransactionSuccessful;
		},
		createTransactionPostBody() {
			return {
				'dealerID': Number(this.selected.dealershipid),
				// ConsumerLeadID
				'customerReference': this.leadID,
				'amount': Number(this.grandTotalAmount),
				// Will get this from the first call to authvia when user clicks "Place Order"
				'paymentMethodID': this.paymentMethodID,
				// JDE Order Number - we do not have this here - sending 0 for now
				'salesOrderNumber': 0
			};
		}
	},

	methods: {
		...mapMutations('authvia', [
			'setPaymentMethodSuccess',
			'setIsCreatingTransaction',
			'setIsCreateTransactionSuccessful'
		]),
		...mapActions('authvia', ['clearAuthviaSessionStorage']),
		async createAuthviaTransaction() {
			try {
				const response = await axios.post('/authvia/create-transaction', { ...this.createTransactionPostBody });
				const { data } = response;

				/** data: {
					"transactionID": String,
					"status": "success"
				} */
				if (data.status !== 'success' || data.error !== 'undefined') {
					this.isTransactionError = true;
				}

				this.setIsCreateTransactionSuccessful(true);

				// clear session storage for next time and avoid transaction errors
				this.clearAuthviaSessionStorage();

				this.$emit('paymentMethodSuccess', this.paymentMethodSuccess);
			} catch (err) {
				axios.post('/service/errorlog', {
					data: {
						message: 'Error occured while trying to create the transaction.',
						error: err
					}
				});
				this.isTransactionError = true;
			} finally {
				this.setIsCreatingTransaction(false);
			}
		}
	},

	mounted() {
		const authViaWidget = document.getElementById('avcc');

		if (authViaWidget) {

			/**
			 * Fires once all fields have been populated
			 */
			authViaWidget.addEventListener('submit', (e) => {
				console.log('submit: ', e);
			});

			/**
			 * Fires if any error happens within the form. (IE: Required feild not filled out)
			 */
			authViaWidget.addEventListener('error', (e) => {
				console.log('error: ', e);
			});

			/**
			 * When the form is submitted with a successful response form Authvia.
			 */
			authViaWidget.addEventListener('success', async (paymentMethod) => {
				this.setIsCreatingTransaction(true);

				const paymentDetails = paymentMethod.detail[0];

				this.setPaymentMethodSuccess(paymentDetails);

				// TODO: Check appiontment time and availability
				// TODO: If we fail, go back to schedule page
				// TODO: What happens when we land back on Payment page after selecting new timeslot??

				// TODO: Once appointment time has been confirmed - Charge the card with information from our last minilith call
				await this.createAuthviaTransaction();
			});
		}
	}
};
</script>
